@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "atwriter";
  src: url("../public/fonts/another_typewriter/atwriter.ttf");
}

@font-face {
  font-family: "anton";
  src: url("../public/fonts/Anton/Anton-Regular.ttf");
}

@font-face {
  font-family: "antonio";
  src: url("../public/fonts/Antonio/Antonio-VariableFont_wght.ttf");
}

@font-face {
  font-family: "aracnoide";
  src: url("../public/fonts/aracnoide/aracnoide.ttf");
}

@font-face {
  font-family: "bloody";
  src: url("../public/fonts/bloody/BLOODY.TTF");
}

@font-face {
  font-family: "bombulate";
  src: url("../public/fonts/bombulate/Bombulate.ttf");
}

@font-face {
  font-family: "cheap-fire";
  src: url("../public/fonts/cheap_fire/chp-fire.ttf");
}

@font-face {
  font-family: "creature";
  src: url("../public/fonts/creature/CREATURE.TTF");
}

@font-face {
  font-family: "flanella";
  src: url("../public/fonts/flanella/Flanella.ttf");
}

@font-face {
  font-family: "freehand";
  src: url("../public/fonts/Freehand/Freehand-Regular.ttf");
}

@font-face {
  font-family: "horror-hotel";
  src: url("../public/fonts/horror_hotel/horrh___.ttf");
}

@font-face {
  font-family: "kanitthin";
  src: url("../public/fonts/Kanit/Kanit-Thin.ttf");
}

@font-face {
  font-family: "kanitregular";
  src: url("../public/fonts/Kanit/Kanit-Regular.ttf");
}

@font-face {
  font-family: "kanitblack";
  src: url("../public/fonts/Kanit/Kanit-Black.ttf");
}

@font-face {
  font-family: "latothin";
  src: url("../public/fonts/Lato/Lato-Thin.ttf");
}

@font-face {
  font-family: "latoregular";
  src: url("../public/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "latoBlack";
  src: url("../public/fonts/Lato/Lato-Black.ttf");
}


@font-face {
  font-family: "LibreBaskervilleRegular";
  src: url("../public/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: "LibreBaskervilleItalic";
  src: url("../public/fonts/Libre_Baskerville/LibreBaskerville-Italic.ttf");
}

@font-face {
  font-family: "LibreBaskervilleBold";
  src: url("../public/fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf");
}

@font-face {
  font-family: "maria";
  src: url("../public/fonts/maria_2/maria.ttf");
}

@font-face {
  font-family: "N8htmare";
  src: url("../public/fonts/n8ghtmare/N8ghtmare.ttf");
}

@font-face {
  font-family: "parisienneRegular";
  src: url("../public/fonts/Parisienne/Parisienne-Regular.ttf");
}


@font-face {
  font-family: "poppinsThin";
  src: url("../public/fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
  font-family: "poppinsRegular";
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "poppinsBlack";
  src: url("../public/fonts/Poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: "quicksand";
  src: url("../public/fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
}
@font-face {
  font-family: "sourceserifproLight";
  src: url("../public/fonts/Source_Serif_Pro/SourceSerifPro-Light.ttf");
}

@font-face {
  font-family: "sourceserifproRegular";
  src: url("../public/fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf");
}

@font-face {
  font-family: "sourceserifproBlack";
  src: url("../public/fonts/Source_Serif_Pro/SourceSerifPro-Black.ttf");
}

@font-face {
  font-family: "lora";
  src: url("../public/fonts/Lora/Lora-VariableFont_wght.ttf");
}


#root{
    overflow: hidden;
}


/* STYLE FOR NOTE -- NOTEBOOK */
body {
    font: 12px/20px "Lucida Grande", Tahoma, Verdana, sans-serif;
    color: #404040;
    background: #fef3c7;
  }
  
  .notepaper {
    position: relative;
    margin: 30px auto;
    padding: 29px 20px 20px 45px;
    width: 280px;
    line-height: 30px;
    color: #6a5f49;
    text-shadow: 0 1px 1px white;
    background-color: #f2f6c1;
    background-image: -webkit-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 90%), -webkit-repeating-linear-gradient(top, transparent 0%, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
    background-image: -moz-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 90%), -moz-repeating-linear-gradient(top, transparent 0%, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
    background-image: -o-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 90%), -o-repeating-linear-gradient(top, transparent 0%, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
    border: 1px solid #c3baaa;
    border-color: rgba(195, 186, 170, 0.9);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), inset 0 0 5px #d8e071, 0 0 1px rgba(0, 0, 0, 0.1), 0 2px rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), inset 0 0 5px #d8e071, 0 0 1px rgba(0, 0, 0, 0.1), 0 2px rgba(0, 0, 0, 0.02);
  }
  
  .notepaper:before, .notepaper:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
  }
  
  .notepaper:before {
    left: 28px;
    width: 2px;
    border: solid #efcfad;
    border-color: rgba(239, 207, 173, 0.9);
    border-width: 0 1px;
  }
  
  .notepaper:after {
    z-index: -1;
    left: 0;
    right: 0;
    background: rgba(242, 246, 193, 0.9);
    border: 1px solid rgba(170, 157, 134, 0.7);
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  
  .quote {
    font-family: Georgia, serif;
    font-size: 14px;
  }
  
  .curly-quotes:before, .curly-quotes:after {
    display: inline-block;
    vertical-align: top;
    height: 30px;
    line-height: 48px;
    font-size: 50px;
    opacity: .2;
  }
  
  .curly-quotes:before {
    content: '\201C';
    margin-right: 4px;
    margin-left: -8px;
  }
  
  .curly-quotes:after {
    content: '\201D';
    margin-left: 4px;
    margin-right: -8px;
  }
  
  .quote-by {
    display: block;
    padding-right: 10px;
    text-align: right;
    font-size: 13px;
    font-style: italic;
    color: #84775c;
  }
  
  .lt-ie8 .notepaper {
    padding: 15px 25px;
  }
  



  .tab-color-width{
    min-width:50px !important;
  }




.line-clamp-10 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
}


.scroll-container::-webkit-scrollbar {
  width: 0.1em;
  background-color: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/*
general styles
*/
.custom-height-content{

  height: 95vh; /* Altura para md y superior */
}

@media (max-width: 767px) {
  .custom-height-content{
    height: 100vh;
  }
}

.scroll-alt::-webkit-scrollbar {
    width: 8px; /*ancho del scroll vertical*/
    height: 8px; /*alto del scroll horizontal*/
  
}


.bottomNavigationIcon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Ajusta la opacidad según tus preferencias */
  border-radius: 50%;
  z-index: -1;
}



/* CSS PARA SCROLL */
elemento::-webkit-scrollbar-track {
  background-color: transparent; /*color de la barra de fondo*/
}
elemento::-webkit-scrollbar-thumb {
  background-color: #09C; /*color del manejador*/
}
elemento::-webkit-scrollbar {
  width: 8px; /*ancho del scroll vertical*/
  height: 8px; /*alto del scroll horizontal*/
}



